html {
	height: 100%;
	font-size: 62.5%;
}
body {
	height: 100%;
	position: relative;
	overflow-x: hidden;
	font-size: tovw(18);
	color: #fff;
	font-family: $roboto;
	background-color: #000;
	&.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
		height: 100% !important;
	}
}
img {
	max-width: 100% !important;
}
#root {
	position: relative;
	min-height: 100%;
}
button {
	outline: none !important;
}
a {
	color: #fff;
	transition: color 0.25s ease;
	outline: none !important;
	&:hover {
		color: #000;
		text-decoration: none;
	}
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
	padding-left: 1rem;
	padding-right: 1rem;
}
.container {
	width: 100%;
	max-width: tovw(1400) !important;
	padding-left: 1rem;
	padding-right: 1rem;
	&--content {
		max-width: tovw(1400) !important;
	}
}
.row {
	margin-left: -1rem;
	margin-right: -1rem;
}
.swal2-shown,
.ReactModal__Body--open {
	#root {
		//filter: blur(5px);
	}
}
.swal2-container.swal2-backdrop-show {
	background-color: rgba(0, 0, 0, 0.6) !important;
	overflow-y: auto !important;
	.swal2-popup {
		display: flex;
	}
}
.swal2-container {
	.swal2-popup {
		height: auto !important;
		min-width: tovw(680) !important;
		min-height: tovw(333) !important;
		background: #160334 !important;
		border: 1px solid #6c12ff !important;
		padding-top: 5vw;
		padding-bottom: 2vw;
		border-radius: 0 !important;
		padding-left: 0;
		padding-right: 0;
		.swal2-close,
		.close {
			position: absolute;
			width: 14px;
			height: 14px;
			// background: url(#{$cdnUrl}/images/close.png) no-repeat center;
			background-size: 100%;
			top: 10px;
			right: 10px;
			opacity: 1;
			font-size: 4rem;
			outline: none !important;
			color: #000 !important;
			font-family: serif;
			font-weight: 300;
			z-index: 9;
			text-indent: -9999px;
		}
		#swal2-content {
			font-size: tovw(20);
			color: #f3e1b9;
			line-height: 150%;
			text-transform: uppercase;
			p {
				font-size: tovw(20);
				color: #fff;
				line-height: 150%;
				text-transform: uppercase;
			}
		}
		#swal2-title,
		h2 {
			font-size: tovw(32);
			font-weight: normal;
			text-align: center;
			text-transform: uppercase;
			margin-left: auto;
			margin-right: auto;
			padding-left: 1rem;
			padding-right: 1rem;
			color: #fff !important;
			justify-content: center;
			margin-bottom: 0;
			font-family: $font-heading;
			background: #6c12ff;
			margin-bottom: 2vw;
			width: 100%;
			padding-top: 1vw;
			padding-bottom: 1vw;
			position: absolute;
			width: 100%;
			top: 0;
			left: 0;
		}
		&.popup-image {
			background: transparent !important;
			box-shadow: none !important;
			width: auto !important;
			padding: 0 !important;
			border-radius: 0.5vw !important;
			overflow: hidden;
			img {
				max-width: 100%;
				margin-left: auto;
				margin-right: auto;
				display: block;
				backface-visibility: hidden;
			}
		}
	}
}
.ReactModal__Overlay {
	display: flex !important;
	position: fixed !important;
	top: 0 !important;
	right: 0 !important;
	bottom: 0;
	left: 0 !important;
	flex-direction: row !important;
	align-items: center !important;
	justify-content: center !important;
	padding: 10px !important;
	background: rgba(0, 0, 0, 0.8) !important;
	z-index: 1060 !important;
	overflow-x: hidden !important;
	-webkit-overflow-scrolling: touch !important;
}
.ReactModal__Content {
	display: flex;
	position: relative;
	flex-direction: column;
	font-family: inherit;
	box-sizing: border-box;
	margin: auto;
	border-radius: 0 !important;
	width: tovw(987) !important;
	min-height: tovw(489) !important;
	background: #160334 !important;
	border: 1px solid #6c12ff !important;
	padding: 0 0 !important;
	padding-bottom: 3vw !important;
	text-align: center;
	outline: none;
	.modal-description {
		width: 80%;
		margin-left: auto;
		margin-right: auto;
	}
	&.modal-history {
		width: tovw(890) !important;
		min-height: tovw(530) !important;
		.modal-description {
			width: 94%;
		}
	}
	&.modal-detail {
		width: tovw(1140) !important;
		min-height: tovw(550) !important;
		.modal-description {
			width: 90%;
		}
		.info-tournament {
			width: 100%;
			text-align: left !important;
			&__content {
				width: calc(100% - #{tovw(160)});
				white-space: pre-line;
			}
		}
	}
	&.modal-register-team {
		width: tovw(1000) !important;
		.modal-description {
			width: 90%;
		}
		.btn {
			width: 90%;
		}
	}
	&.modal-manage-team {
		width: tovw(1000) !important;
		.modal-description {
			width: 90%;
		}
	}
	&.modal-team-register,
	&.modal-filter-team {
		width: tovw(800) !important;
		.modal-description {
			width: 90%;
		}
	}
	&.modal-update-score {
		width: tovw(950) !important;
		min-height: tovw(360) !important;
		.modal-description {
			width: 100%;
		}
		.select-tabs {
			a {
				width: 50%;
				margin-right: 0;
				&.fw {
					width: 100%;
				}
			}
		}
		.match-score {
			padding: 0 tovw(60);
		}
		.teams {
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;
			margin-bottom: tovw(30);
			&__item {
				width: 33.3333%;
				border: 1px solid #410b9a;
				text-align: center;
				&--icon {
					border: 0;
					img {
						width: tovw(100);
					}
				}
			}
			&__name {
				font-size: tovw(18);
				text-transform: uppercase;
				font-weight: normal;
				height: tovw(57);
				line-height: tovw(57);
				background-color: #0f0223;
			}
			&__score {
				font-size: tovw(24);
				color: #fff;
				height: tovw(50);
				line-height: tovw(50);
				background-color: #6c12ff;
			}
		}
		.manual-score {
			padding: 0 tovw(60);
			&__list {
				margin-bottom: tovw(37);
			}
			&__row {
				display: flex;
				justify-content: space-between;
				align-items: stretch;
				flex-wrap: wrap;
				margin-bottom: tovw(6);
				> div {
					height: tovw(50);
					line-height: tovw(50);
				}
				&--title {
					> div {
						background-color: #6c12ff !important;
					}
				}
				&:nth-child(odd) {
					> div {
						background-color: #1e0448;
					}
				}
				&:nth-child(even) {
					> div {
						background-color: #0f0223;
					}
				}
			}
			&__name {
				width: 39.5%;
			}
			&__score {
				width: 29.2%;
				.selection {
					border: 0 !important;
					background-color: #0f0223 !important;
					select,
					span {
						background-color: #0f0223 !important;
						font-weight: normal !important;
						font-size: tovw(18);
					}
					&--player2 {
						background-color: #1e0448 !important;
						select,
						span {
							background-color: #1e0448 !important;
						}
					}
				}
			}
			&__actions {
				width: 29.2%;
			}
			&__select-win {
				margin-bottom: tovw(45);
				p {
					font-size: tovw(20);
					text-transform: uppercase;
					margin-bottom: tovw(20);
				}
				&--action {
					a {
						display: inline-block;
						width: tovw(228);
						height: tovw(50);
						line-height: tovw(50);
						text-transform: uppercase;
						font-size: tovw(18);
						border: 1px solid #6c12ff;
						background-color: transparent;
						transition: background-color 0.8s ease;
						color: #fff;
						margin-right: tovw(20);
						&:last-child {
							margin-right: 0;
						}
						&.active,
						&:hover {
							background-color: #6c12ff;
							color: #fff;
						}
					}
				}
			}
		}
	}
	&.modal-my-invitations {
		.modal-description {
			width: 96%;
		}
	}
	&.modal-shop {
		width: tovw(1271) !important;
		.modal-description {
			width: 90%;
		}
	}
	&.modal-ambassador {
		width: tovw(773) !important;
		min-height: tovw(377) !important;
		padding-top: tovw(42) !important;
		padding-bottom: tovw(42) !important;
		.modal-description {
			width: 90%;
		}
	}
	.close {
		position: absolute;
		width: 14px;
		height: 14px;
		// background: url(#{$cdnUrl}/images/close.png) no-repeat center;
		background-size: 100%;
		top: 10px;
		right: 10px;
		opacity: 1;
		font-size: 4rem;
		outline: none !important;
		color: #000 !important;
		font-family: serif;
		font-weight: 300;
		z-index: 9;
		text-indent: -9999px;
	}
	h2 {
		font-size: tovw(30);
		font-weight: normal;
		text-align: center;
		text-transform: uppercase;
		margin-left: auto;
		margin-right: auto;
		padding-left: 1rem;
		padding-right: 1rem;
		color: #fff !important;
		justify-content: center;
		margin-bottom: 0;
		font-family: $font-heading;
		background: #6c12ff;
		margin-bottom: 2vw;
		width: 100%;
		padding-top: 1vw;
		padding-bottom: 1vw;
	}
	p,
	li {
		font-size: tovw(20);
		color: #fff;
	}
}
#main-body {
	position: relative;
}
.main-content {
	position: relative;
	padding-top: tovw(80);
}
.ScrollbarsCustom-Wrapper {
	right: 0 !important;
}
.ScrollbarsCustom-Track {
	width: tovw(10) !important;
	right: tovw(-35) !important;
	z-index: 1;
	background: transparent !important;
	&:before {
		content: '';
		position: absolute;
		width: 1px;
		background: #6c12ff;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		height: 100%;
	}
	.modal-history & {
		right: 0 !important;
		&:before {
			background: #6c12ff;
		}
	}
}
.ScrollbarsCustom-Thumb {
	width: 100% !important;
	border-radius: 0.5vw !important;
	background: #6c12ff !important;
	.modal-history & {
		background: #6c12ff !important;
	}
}
#header {
	z-index: 9;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
}
#footer {
	position: relative;
	background: #000;
	padding-top: 1.5vw;
	padding-bottom: 1.5vw;
	.container {
		width: tovw(1320);
	}
	.footer-logo {
		display: inline-block;
		width: tovw(500);
		margin-bottom: tovw(18);
	}

	p {
		font-size: tovw(17);
		text-transform: uppercase;
		color: #fff;
		text-align: center;
		margin-bottom: 0;
	}
}
.top-bar {
	height: tovw(80);
	border-bottom: 2px solid #e601f8;
	background-color: #03082e;
	padding-top: tovw(8);
	.col-9 {
		text-align: right;
	}
	.container--content {
		max-width: tovw(1700) !important;
	}
}
.user-info {
	display: inline-block;
	margin-right: tovw(40);
}
.nickname {
	display: inline-block;
	max-width: 4vw;
	overflow: hidden;
	vertical-align: bottom;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.text-dot {
	// display: inline-block;
	max-width: tovw(85);
	// overflow: hidden;
	// vertical-align: bottom;
	// text-overflow: ellipsis;
	// white-space: nowrap;
	word-wrap: break-word;
	line-height: 1.2;
}
.logo {
	display: inline-block;
	height: tovw(47);
	margin-top: tovw(8);
	img {
		display: block;
		backface-visibility: hidden;
		height: 100%;
	}
}
.menu-link {
	font-size: tovw(20);
	text-transform: uppercase;
	font-weight: 300;
	margin-right: tovw(40);
	transition: all 0.4s ease;
	&:hover {
		color: #fff;
		font-weight: bold;
	}
}
.my-tournament {
	margin-right: tovw(13);
	img {
		height: tovw(47);
		transition: all 0.5s ease;
	}
	&:hover {
		img {
			transform: scale(0.95);
		}
	}
}
.header-action {
	display: inline-block;
}
.btn-login,
.btn-logout {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: tovw(20);
	text-transform: uppercase;
	height: tovw(47);
	width: tovw(200);
	background: url('/images/btn-header.png') no-repeat;
	background-size: 100% 100%;
	transition: all 0.5s ease;
	img {
		display: inline-block;
		width: tovw(21);
		margin-left: tovw(6);
	}
	&:hover {
		color: #fff;
		transform: scale(0.95);
	}
}
.wrap-video {
	width: 100%;
	position: relative;
	z-index: 1;
	video {
		width: 100%;
		display: none;
		@include max-screen(1200px) {
			display: none;
		}
	}
	img {
		width: 100%;
		display: block;
		@include min-screen(1200px) {
			display: block;
			// display: none;
		}
	}
}
.section {
	position: relative;
	&--2 {
		padding-top: tovw(74);
		padding-bottom: tovw(58);
		background: url('/images/section2.jpg?v=1') no-repeat;
		background-size: 100% 100%;
	}
	&--3 {
		padding-top: tovw(50);
		padding-bottom: tovw(20);
		background: url('/images/section3.jpg?v=1') no-repeat;
		background-size: 100% 100%;
	}
	&--4 {
		padding-top: tovw(78);
		padding-bottom: tovw(0);
		background: url('/images/section2.jpg?v=1') no-repeat;
		background-size: 100% 100%;
	}
}
.title {
	font-size: tovw(50);
	color: #fff;
	text-transform: uppercase;
	font-family: $utm;
}
.sub-date {
	font-size: tovw(30);
	font-weight: 300;
	margin-bottom: 0;
}
.sub-title {
	font-size: tovw(18);
	font-weight: 300;
	margin-bottom: tovw(20);
	&--2 {
		width: tovw(900);
		margin-left: auto;
		margin-right: auto;
	}
}
.swiper-button-disabled {
	opacity: 0.3;
}
.swiper-next-player1,
.swiper-next-player2 {
	z-index: 10;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	width: tovw(29) !important;
	height: tovw(62) !important;
	background: url(/images/next.png) no-repeat;
	background-size: 100% 100%;
	position: absolute;
	top: 50%;
	right: tovw(-40) !important;
	margin-top: tovw(-40);
	transition: filter 0.4s ease;
	&::after {
		display: none;
	}
	&:hover {
		filter: brightness(1.3);
	}
}
.swiper-prev-player1,
.swiper-prev-player2 {
	z-index: 10;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	width: tovw(29) !important;
	height: tovw(62) !important;
	background: url(/images/prev.png) no-repeat;
	background-size: 100% 100%;
	position: absolute;
	top: 50%;
	left: tovw(-40) !important;
	margin-top: tovw(-40);
	transition: filter 0.4s ease;
	&::after {
		display: none;
	}
	&:hover {
		filter: brightness(1.3);
	}
}
.list-players {
	margin-bottom: tovw(50);
}
.player {
	&__info {
		display: inline-block;
		border: 1px solid #6c12ff;
		position: relative;
		margin-bottom: tovw(6);
		width: 100%;
		img {
			height: tovw(300);
			object-fit: cover;
		}
		span {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: tovw(40);
			line-height: tovw(40);
			text-align: center;
			font-size: tovw(18);
			color: #fff;
			background-color: rgba(0, 0, 0, 0.8);
			text-transform: uppercase;
		}
	}
	.btn {
		width: 100%;
	}
	p {
		margin-bottom: 0;
		margin-top: tovw(2);
	}
	a {
		pointer-events: none;
	}
	&.ambassador {
		a {
			pointer-events: all;
		}
	}
}
.list-tournament {
	text-align: center;
	margin-bottom: tovw(112);
	&--bxh {
		width: tovw(730);
		margin-left: auto;
		margin-right: auto;
	}
	&--rankings {
		width: tovw(822);
		margin-left: auto;
		margin-right: auto;
	}
	&__row {
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		flex-wrap: wrap;
		font-size: tovw(18);
		div {
			padding: tovw(15) 0;
			display: flex;
			justify-content: center;
			align-items: center;
			min-height: tovw(67);
		}
		&--title {
			margin-bottom: tovw(8);
			> div {
				padding: 0;
				text-transform: uppercase;
				height: tovw(50);
				line-height: tovw(50);
				background-color: #6c12ff !important;
			}
		}
		&:nth-child(odd) {
			> div {
				background-color: #1d0b59;
			}
		}
		&:nth-child(even) {
			> div {
				background-color: #0f0a3c;
			}
		}
	}
	&__name {
		width: tovw(258);
		display: flex;
		justify-content: center;
		align-items: center;
		text-transform: uppercase;
		img {
			display: inline-block;
			width: tovw(42);
			margin-right: tovw(13);
		}
	}
	&__request {
		width: tovw(244);
	}
	&__type {
		width: tovw(102);
	}
	&__total {
		width: tovw(104);
	}
	&__address {
		width: tovw(400);
	}
	&__stt {
		width: tovw(168);
		&.small {
			width: tovw(84);
			font-size: 100% !important;
		}
	}
	&__name-player {
		width: tovw(555);
		text-transform: uppercase;
		&.small {
			width: tovw(264);
			font-size: 100% !important;
		}
	}
	&__actions {
		width: tovw(245);
		display: flex;
		justify-content: center;
		align-items: center;
		a {
			color: #43d2fd;
			text-transform: uppercase;
			display: inline-block;
			width: tovw(106);
			height: tovw(47);
			line-height: tovw(47);
			background: url(/images/btn-blue.png) no-repeat;
			background-size: 100% 100%;
			&.btn-register {
				background: url(/images/btn-red.png) no-repeat;
				background-size: 100% 100%;
				margin-left: tovw(6);
				span {
					background: #ff2e50;
					background: linear-gradient(to right, #ff2e50 0%, #de00ff 100%);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
				}
			}
		}
	}
	&__medal {
		width: tovw(104);
		img {
			width: tovw(29);
		}
	}
	&__total-point {
		width: tovw(127);
	}
}
.selection {
	height: tovw(51);
	width: 100%;
	padding-left: 10px;
	padding-right: 10px;
	transition: border-color 0.25s ease;
	text-transform: none;
	border: 1px solid #6c12ff;
	background: rgba(28, 12, 81, 0.4);
	outline: none !important;
	box-shadow: none !important;
	color: #fff;
	font-weight: 300;
	&--collaborator {
		width: tovw(400);
		margin-bottom: tovw(30);
	}
	&:focus,
	&:active {
		border-color: #3a7fff;
	}
	&::-webkit-input-placeholder {
		/* Chrome/Opera/Safari */
		color: #fff;
	}
	&::-moz-placeholder {
		/* Firefox 19+ */
		color: #fff;
	}
	&:-ms-input-placeholder {
		/* IE 10+ */
		color: #fff;
	}
	&:-moz-placeholder {
		/* Firefox 18- */
		color: #fff;
	}
}
.selection {
	position: relative;
	display: inline-block;
	select,
	span {
		position: absolute;
		width: 100%;
		height: 100%;
		background: rgba(28, 12, 81, 0.4);
		top: 0;
		left: 0;
		line-height: tovw(51);
		font-size: tovw(18);
		padding-left: 0.5vw;
		padding-right: tovw(45);
		color: #fff;
		text-transform: uppercase;
		text-align: center;
		font-weight: 300;
	}
	select {
		opacity: 0;
	}
	span {
		&:before {
			content: '';
			position: absolute;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0.75vw 0.5vw 0 0.5vw;
			border-color: #fff transparent transparent transparent;
			top: 40%;
			right: 1vw;
		}
		&.not-arrow {
			&::before {
				display: none;
			}
		}
	}
	&--register {
		span {
			font-size: tovw(14);
		}
	}
}
.filter {
	margin-bottom: tovw(15);
	display: flex;
	justify-content: flex-end;
	align-items: center;
	.selection {
		width: tovw(264);
		&:nth-child(1) {
			margin-right: tovw(8);
		}
	}
}
.select-tabs {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: tovw(30);
	a {
		display: inline-block;
		width: tovw(228);
		height: tovw(47);
		line-height: tovw(47);
		background: url(/images/border-gradient-l.png) no-repeat;
		background-size: 100% 100%;
		font-size: tovw(20);
		font-weight: 300;
		text-transform: uppercase;
		text-align: center;
		transition: all 0.8s ease;
		margin-right: tovw(20);
		&.active,
		&:hover {
			color: #fff;
			background: url(/images/btn-gradient-l.png) no-repeat;
			background-size: 100% 100%;
		}
		&:last-child {
			margin-right: 0;
		}
	}
}

.match-branch {
	margin: 0 tovw(90);
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: tovw(30);
	border-top: 1px solid #12394f;
	margin-bottom: tovw(40);
	a {
		display: inline-block;
		width: tovw(224);
		height: tovw(47);
		line-height: tovw(47);
		background: url(/images/border-blue-l.png) no-repeat;
		background-size: 100% 100%;
		font-size: tovw(20);
		font-weight: 300;
		text-transform: uppercase;
		text-align: center;
		transition: all 0.8s ease;
		margin-right: tovw(20);
		color: #43d2fd;
		&.active,
		&:hover {
			color: #080a26;
			background: url(/images/btn-gradient-blue.png) no-repeat;
			background-size: 100% 100%;
			font-weight: bold;
		}
		&:last-child {
			margin-right: 0;
		}
	}
}
.info-tournament {
	width: tovw(730);
	margin-left: auto;
	margin-right: auto;
	&__row {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		margin-bottom: tovw(45);
	}
	&__label {
		text-transform: uppercase;
		color: #8840fe;
		font-size: tovw(18);
		font-weight: normal;
		width: tovw(160);
	}
	&__content {
		font-size: tovw(18);
		color: #fff;
		font-weight: normal;
		width: tovw(570);
		padding: 0 tovw(20);
	}
}
.bracket {
	display: flex;
	justify-content: center;
	align-items: center;
	> div {
		&[dir='ltr'],
		&[dir='rtl'] {
			justify-content: flex-end;
		}
	}
	&--bk,
	&--16 {
		> div {
			&[dir='ltr'],
			&[dir='rtl'] {
				.bracket__round {
					padding-top: tovw(100);
				}
			}
		}
		.bracket__win {
			margin-top: tovw(-30);
		}
	}
	&__round {
		position: relative;
		padding-top: tovw(60);
		z-index: 2;
		&:last-child {
			.bracket__title {
				width: 200%;
				left: 0;
				transform: translateX(0);
				&.right {
					display: none;
				}
			}
		}
		&--right {
			&:last-child {
				.right {
					display: none;
				}
			}
			.bracket__seed-team {
				padding-right: tovw(22) !important;
				padding-left: tovw(30) !important;
			}
			.bracket__score {
				right: inherit;
				left: 0;
				border-right: 1px solid #4bfced;
				border-left: 0;
			}
			.bracket__logo {
				left: inherit;
				right: tovw(-22);
			}
			.bracket__stt {
				right: inherit;
				left: 0;
			}
			.bracket__seed-item {
				background-color: transparent !important;
				box-shadow: none !important;
				border-radius: 0 !important;
				&--even {
					&.bracket__seed-item--2 {
						&::after {
							margin-top: tovw(-50) !important;
						}
					}
					&::after {
						margin-top: tovw(-10) !important;
						border-left: 0;
						border-top: 0 !important;
						border-bottom: 1px solid #47ede1 !important;
					}
				}
				&--special {
					&::after {
						height: tovw(100) !important;
						margin-top: tovw(50) !important;
					}
				}
				&::before {
					content: '';
					position: absolute;
					top: tovw(20);
					right: 100%;
					width: tovw(20);
					height: calc(100% - #{tovw(40)});
					border: 1px solid #47ede1;
					border-right: 0;
				}
				&::after {
					content: '';
					position: absolute;
					top: 50%;
					right: 100%;
					transform: translateY(-50%);
					margin-right: tovw(20);
					margin-top: tovw(10);
					width: tovw(100);
					height: tovw(20);
					border: 1px solid #47ede1;
					border-right: 0;
					border-bottom: 0;
				}
			}
		}
	}
	&__match {
		min-width: tovw(200) !important;
		padding: tovw(20) tovw(25) !important;
		&::after {
			display: none;
		}
		&::before {
			display: none;
		}
	}
	&__seed-item {
		background-color: transparent !important;
		box-shadow: none !important;
		border-radius: 0 !important;
		&.no-line {
			&::after {
				display: none;
			}
			&::before {
				height: 1px !important;
				border: 0 !important;
				background-color: #47ede1 !important;
				width: tovw(30) !important;
			}
		}
		&--even {
			&.bracket__seed-item--2 {
				&::after {
					margin-top: tovw(-50) !important;
				}
			}
			&::after {
				margin-top: tovw(-10) !important;
				border-left: 0;
				border-top: 0 !important;
				border-bottom: 1px solid #47ede1 !important;
			}
		}
		&--special {
			&::after {
				height: tovw(100) !important;
				margin-top: tovw(50) !important;
			}
		}
		&::before {
			content: '';
			position: absolute;
			top: tovw(20);
			right: tovw(-20);
			width: tovw(20);
			height: calc(100% - #{tovw(40)});
			border: 1px solid #47ede1;
			border-left: 0;
		}
		&::after {
			content: '';
			position: absolute;
			top: 50%;
			left: 100%;
			transform: translateY(-50%);
			margin-left: tovw(20);
			margin-top: tovw(10);
			width: tovw(100);
			height: tovw(20);
			border: 1px solid #47ede1;
			border-left: 0;
			border-bottom: 0;
		}
	}
	&__seed-team {
		height: tovw(40) !important;
		line-height: tovw(40) !important;
		border-radius: 0 !important;
		border: 1px solid #4bfced;
		background-color: #180570;
		text-align: center;
		font-size: tovw(14) !important;
		color: #fff !important;
		font-weight: normal;
		justify-content: center !important;
		position: relative;
		padding-left: tovw(22) !important;
		padding-right: tovw(30) !important;
		&--first {
			margin-bottom: tovw(43);
		}
	}
	&__logo {
		width: tovw(44);
		height: tovw(44);
		border: 1px solid #fff;
		border-radius: 50%;
		background-color: #180570;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 50%;
		left: tovw(-22);
		transform: translateY(-50%);
		cursor: pointer;
		img {
			width: tovw(25);
		}
	}
	&__score {
		position: absolute;
		top: 50%;
		right: 0;
		width: tovw(30);
		height: 100%;
		transform: translateY(-50%);
		border-left: 1px solid #4bfced;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: tovw(14);
		color: #fff;
	}
	&__stt {
		color: #4bfdee;
		font-size: tovw(14);
		font-weight: 600;
		text-transform: uppercase;
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
	}
	&__title {
		color: #4bfdee;
		font-size: tovw(18);
		font-weight: 600;
		text-transform: uppercase;
		height: tovw(30);
		text-align: center;
		width: tovw(150);
		margin-left: auto;
		margin-right: auto;
		background: url(/images/bg-title-bracket.png) no-repeat;
		background-size: 100% 100%;
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		&--3,
		&--4 {
			top: tovw(70);
		}
		// &--4 {
		//   width: 200%;
		//   left: 0;
		//   transform: translateX(0);
		//   // &.right {
		//   //   display: none;
		//   // }
		// }
	}
	&__win {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		margin-top: tovw(-50);
		width: tovw(150);
		&::before {
			content: '';
			position: absolute;
			left: 50%;
			top: 100%;
			transform: translateX(-50%);
			width: 1px;
			height: tovw(60);
			background-color: #39bcb9;
		}
		&::after {
			content: '';
			position: absolute;
			left: 50%;
			top: 100%;
			transform: translateX(-50%);
			margin-top: tovw(60);
			height: 1px;
			width: tovw(300);
			background-color: #39bcb9;
		}
		.bracket__seed-team {
			width: 100%;
			padding-right: 0 !important;
			background: rgb(68, 204, 255);
			background: linear-gradient(
				0deg,
				rgb(68, 204, 255) 0%,
				rgb(57, 255, 239) 100%
			);
			color: #080a26 !important;
			font-weight: bold;
			font-size: tovw(20) !important;
			p {
				padding: 0 tovw(10);
			}
		}
	}
}
.mb-0 {
	margin-bottom: 0 !important;
}
form > .row {
	height: 100%;
	margin-bottom: tovw(30);
	.select-tabs {
		margin-bottom: 0;
	}
}
label {
	text-align: left;
	display: block;
	strong {
		color: #ff0974;
	}
	&.cmnd {
		padding-top: tovw(10);
	}
}
.hr-form {
	width: 100%;
	height: 1px;
	background: #4a0cae;
	margin-top: 0;
	margin-bottom: tovw(30);
}
.title-league {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	z-index: 4;
	img {
		width: tovw(1727);
	}
}
.table-history {
	width: 100%;
	tr {
		th,
		td {
			font-size: tovw(20);
			color: #fff;
		}
		th {
			font-weight: bold;
			color: #fff;
		}
		td {
			padding: tovw(8) 0;
		}
	}
}
.team-actions {
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	margin-left: tovw(-100);
	display: flex;
	align-items: center;
	.btn {
		height: tovw(53) !important;
		line-height: tovw(53) !important;
		font-weight: normal;
		&:first-child {
			margin-right: tovw(20);
		}
	}
}
.text-error {
	font-size: tovw(16) !important;
}
.text-italic {
	font-style: italic;
}
.list-member {
	padding: 0 tovw(60);
	> .row {
		margin-bottom: tovw(15);
		border-bottom: 1px solid #4a0cae;
		padding-bottom: tovw(15);
	}
	&__actions {
		display: flex;
		justify-content: space-between;
		.btn {
			width: tovw(140) !important;
		}
	}
}
.table-member {
	width: 100%;
	thead {
		tr {
			background-image: -moz-linear-gradient(
				0deg,
				rgb(255, 46, 80) 0%,
				rgb(222, 0, 255) 49%,
				rgb(0, 36, 255) 100%
			) !important;
			background-image: -webkit-linear-gradient(
				0deg,
				rgb(255, 46, 80) 0%,
				rgb(222, 0, 255) 49%,
				rgb(0, 36, 255) 100%
			) !important;
			background-image: -ms-linear-gradient(
				0deg,
				rgb(255, 46, 80) 0%,
				rgb(222, 0, 255) 49%,
				rgb(0, 36, 255) 100%
			) !important;
			th {
				font-size: tovw(24);
				text-transform: uppercase;
				padding: tovw(20) 0;
				text-align: center;
				font-family: $utm;
			}
		}
	}
	tbody {
		tr {
			&:nth-child(even) {
				background: #1e075d;
			}
		}
		td {
			font-size: tovw(18);
			text-align: center;
			padding: tovw(20) 0;
		}
	}
}

.filter-team {
	position: relative;
	width: tovw(450);
	margin: 0 auto tovw(40) tovw(25);
	input {
		width: 100%;
		height: tovw(52);
		border: 0;
		background: url(/images/input-search.png) no-repeat;
		background-size: 100% 100%;
		border: 0;
		padding-right: tovw(50);
	}
	a {
		position: absolute;
		top: 50%;
		right: tovw(10);
		transform: translateY(-50%);
		img {
			width: tovw(30);
		}
	}
	.selection {
		position: absolute;
		top: 50%;
		right: 0;
		margin-right: tovw(-220);
		transform: translateY(-50%);
		width: tovw(200);
	}
}
.my-invitations {
	&__item {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		border-bottom: 1px solid #4a0cae;
		margin-bottom: tovw(20);
		padding-bottom: tovw(15);
	}
	&__noti {
		width: 50%;
		p {
			margin-bottom: 0;
		}
		span {
			color: #e601f8;
		}
	}
	&__actions {
		width: 50%;
	}
}

.notifications {
	padding-top: tovw(70);
	p {
		font-size: tovw(26);
		text-transform: uppercase;
	}
	> div {
		margin-bottom: tovw(20);
	}
}
.btn-shop {
	display: inline-block;
	width: tovw(211);
	height: tovw(47);
	line-height: tovw(47);
	text-align: center;
	border: 1px solid #f405a2;
	font-size: tovw(18);
	text-transform: uppercase;
	font-family: $roboto;
	font-weight: 300;
	color: #f405a2;
	margin-bottom: tovw(24);
	background-color: transparent;
	transition: background-color 0.3s ease, color 0.3s ease;
	&.active,
	&:hover {
		background-color: #f405a2;
		color: #fff;
	}
}
.shop {
	width: tovw(1080);
	margin: tovw(35) auto 0 auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	&__item {
		width: tovw(194);
		margin-bottom: tovw(45);
	}
	&__img {
		position: relative;
		width: 100%;
		height: tovw(194);
		background-color: #fff;
		display: flex;
		justify-content: center;
		padding-top: tovw(30);
		margin-bottom: tovw(20);
		img {
			width: tovw(144);
			height: auto;
			object-fit: contain;
		}
	}
	&__btn {
		display: block;
		height: tovw(53);
		line-height: tovw(53);
		text-align: center;
		background-color: #ea02f3;
		text-transform: uppercase;
		font-size: tovw(24);
		color: #fff;
		font-weight: 500;
		border-radius: tovw(26);
	}
	&__limit {
		position: absolute;
		top: tovw(5);
		right: tovw(10);
		font-size: tovw(18);
		font-weight: bold;
		color: #161349;
		text-transform: uppercase;
		&--server {
			left: tovw(10);
			right: inherit;
		}
	}
}
.input-hidden {
	opacity: 0;
	visibility: hidden;
	height: 0 !important;
	border: 0 !important;
	display: none;
}

.btn-his-wrap {
	width: tovw(750);
	margin-left: auto;
	margin-right: auto;
	height: tovw(54);
	align-items: flex-end;
	margin-bottom: tovw(40);
}
.btn-his {
	display: inline-block;
	height: tovw(47);
	display: inline-block;
	font-size: tovw(28);
	line-height: tovw(47);
	text-transform: uppercase;
	color: #fff !important;
	font-weight: bold;
	margin-bottom: tovw(3);
	border: tovw(2) solid #6c12ff;
	flex: 0 0 calc(50% - #{tovw(3)});
	max-width: calc(50% - #{tovw(3)});
	&--active {
		background: #6c12ff;
	}
}
.btn-group-team {
	display: flex;
	justify-content: space-between;
	align-items: center;
	.btn--reject {
		min-width: tovw(120) !important;
	}
}
.ambassador-tab {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: tovw(30);
	margin-top: tovw(30);
	margin-bottom: tovw(30);
}
.modal-ambassador {
	&__wrap {
		display: flex;
		justify-content: center;
		gap: tovw(15);
	}

	&__img {
		width: tovw(200);
		height: tovw(300);
		border: tovw(1) solid #6c12ff;
		position: relative;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	&__level {
		position: absolute;
		bottom: tovw(10);
		left: tovw(10);
		width: tovw(68);
		img {
			width: 100%;
			height: auto;
			object-fit: none;
		}
	}
	&__info {
		width: tovw(426);
		text-align: left;
	}
	&__name {
		display: inline-block;
		font-size: tovw(18);
		text-transform: uppercase;
		color: #fff;
		padding: 0 tovw(50);
		height: tovw(40);
		line-height: tovw(42);
		margin-bottom: tovw(30);
		margin-top: tovw(20);
		background-image: -moz-linear-gradient(
			0deg,
			rgb(0, 36, 255) 0%,
			rgb(222, 0, 255) 49%,
			rgb(255, 46, 80) 100%
		) !important;
		background-image: -webkit-linear-gradient(
			0deg,
			rgb(0, 36, 255) 0%,
			rgb(222, 0, 255) 49%,
			rgb(255, 46, 80) 100%
		) !important;
		background-image: -ms-linear-gradient(
			0deg,
			rgb(0, 36, 255) 0%,
			rgb(222, 0, 255) 49%,
			rgb(255, 46, 80) 100%
		) !important;
	}
	&__detail {
		padding: tovw(30) 0;
		font-size: tovw(18);
		color: #fff;
	}
	.hr {
		width: 100%;
		height: tovw(1);
		background-color: #4a0cae;
	}
}
.icon-info {
	display: inline-block;
	margin-right: tovw(30);
	img {
		width: tovw(100);
		height: auto;
		animation: tada 2s linear infinite;
	}
}
