.btn,
.swal2-container .swal2-popup .swal2-styled.swal2-confirm,
.swal2-container .swal2-popup .swal2-styled.swal2-cancel {
  // background: url(/images/btn-gray.png) no-repeat !important;
  background-size: 100% 100% !important;
  font-size: tovw(24);
  text-transform: uppercase;
  color: #161349 !important;
  height: tovw(52);
  line-height: tovw(52);
  padding: 0 tovw(15);
  outline: none !important;
  border: 0 !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 0;
  min-width: tovw(185);
  position: relative;
  font-weight: bold;
  transition: transform 0.25s ease;
  // font-style: italic;
  @include min-screen(1200px) {
    &:hover {
      transform: translateY(-0.5rem);
    }
  }
  &--fw {
    width: 100%;
    min-width: auto;
  }
  &--gradient {
    background-image: -moz-linear-gradient( 0deg, rgb(0,36,255) 0%, rgb(222,0,255) 49%, rgb(255,46,80) 100%) !important;
    background-image: -webkit-linear-gradient( 0deg, rgb(0,36,255) 0%, rgb(222,0,255) 49%, rgb(255,46,80) 100%) !important;
    background-image: -ms-linear-gradient( 0deg, rgb(0,36,255) 0%, rgb(222,0,255) 49%, rgb(255,46,80) 100%) !important;
    height: tovw(40) !important;
    line-height: tovw(36) !important;
    font-size: tovw(18) !important;
    color: #fff !important;
  }
  &--medium {
    height: tovw(50) !important;
    line-height: tovw(50) !important;
    font-weight: normal;
  }
  &--reject {
    background: url(/images/btn-reject.png) no-repeat !important;
    background-size: 100% 100% !important;
    font-size: tovw(18) !important;
    color: #fff !important;
  }
  &--exit {
    background-color: #f01a88 !important;
    font-size: tovw(18) !important;
    color: #fff !important;
  }
  &--submit {
    min-width: tovw(228) !important;
    height: tovw(47) !important;
    line-height: tovw(47) !important;
    background: url(/images/btn-gradient-l.png) no-repeat !important;
    background-size: 100% 100% !important;
    font-size: tovw(20) !important;
    color: #fff !important;
    font-weight: normal !important;
  }
}

.swal2-container .swal2-popup .swal2-styled.swal2-confirm,
.swal2-container .swal2-popup .swal2-styled.swal2-cancel {
  min-width: tovw(228) !important;
  height: tovw(47) !important;
  line-height: tovw(47) !important;
  background: url(/images/btn-gradient-l.png) no-repeat !important;
  background-size: 100% 100% !important;
  font-size: tovw(20) !important;
  color: #fff !important;
  font-weight: normal !important;
}
