.form {
	max-width: 410px;
	margin: 0 auto;
	.error {
		color: $red;
		text-align: center;
		width: 100%;
		display: block;
	}
}
button {
	border: 0;
	outline: none !important;
}
.form-line {
	margin-bottom: tovw(30);
	font-size: tovw(18);
	&:last-of-type {
		margin-bottom: 0;
	}
}
input[type='text'],
input[type='email'],
input[type='password'],
input[type='number'],
input[type='url'],
input[type='search'],
input[type='tel'],
input[type='date'],
input[type='datalist'],
input[type='time'],
textarea,
.selection {
	height: tovw(50);
	width: 100%;
	padding-left: 10px;
	padding-right: 10px;
	transition: border-color 0.25s ease;
	text-transform: none;
	border: 1px solid #410b9a;
	background: #0f0223;
	outline: none !important;
	box-shadow: none !important;
	color: #fff;
	border-radius: 0 !important;
	font-size: tovw(18);

	&:focus,
	&:active {
		border-color: #3a7fff;
	}
	&::-webkit-input-placeholder {
		/* Chrome/Opera/Safari */
		color: #fff;
	}
	&::-moz-placeholder {
		/* Firefox 19+ */
		color: #fff;
	}
	&:-ms-input-placeholder {
		/* IE 10+ */
		color: #fff;
	}
	&:-moz-placeholder {
		/* Firefox 18- */
		color: #fff;
	}
}
